import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { ArrowLeft } from 'lucide-react';

const CompanyBalanceDetail = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#8884d8'];

  const months = [
    { value: 1, label: 'Ocak' },
    { value: 2, label: 'Şubat' },
    { value: 3, label: 'Mart' },
    { value: 4, label: 'Nisan' },
    { value: 5, label: 'Mayıs' },
    { value: 6, label: 'Haziran' },
    { value: 7, label: 'Temmuz' },
    { value: 8, label: 'Ağustos' },
    { value: 9, label: 'Eylül' },
    { value: 10, label: 'Ekim' },
    { value: 11, label: 'Kasım' },
    { value: 12, label: 'Aralık' }
  ];

  const years = Array.from(
    { length: 5 },
    (_, i) => new Date().getFullYear() - i
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://kobiyanimda.com/get_company_balance.php?month=${selectedMonth}&year=${selectedYear}`
        );
        setData(response.data || { current: {}, past: {} });
      } catch (error) {
        console.error('Veri alınırken hata oluştu:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedMonth, selectedYear]);

  const getTrafficVsOthers = (company) => {
    if (!data?.current?.[company]) return [];
    
    const trafficCount = Number(data.current[company]['Trafik Sigortası_count'] || 0);
    const trafficAmount = Number(data.current[company]['Trafik Sigortası_amount'] || 0) / 100;
    
    const otherProducts = Object.entries(data.current[company])
      .reduce((acc, [key, value]) => {
        if (key.endsWith('_count') && !key.includes('Trafik')) {
          acc.count += Number(value);
        }
        if (key.endsWith('_amount') && !key.includes('Trafik')) {
          acc.amount += Number(value) / 100;
        }
        return acc;
      }, { count: 0, amount: 0 });

    return [
      { name: 'Trafik', value: trafficCount, amount: trafficAmount },
      { name: 'Trafik Dışı', value: otherProducts.count, amount: otherProducts.amount }
    ];
  };

  const getProductDistribution = (company) => {
    if (!data?.current?.[company]) return [];
    
    return Object.entries(data.current[company])
      .filter(([key]) => key.endsWith('_count'))
      .map(([key, value]) => {
        const productName = key.replace('_count', '');
        const amount = Number(data.current[company][`${productName}_amount`] || 0) / 100;
        
        return {
          name: productName,
          value: Number(value),
          amount: amount
        };
      })
      .filter(item => item.value > 0);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-700"></div>
      </div>
    );
  }

  return (
    <div className="p-4">
      <div className="flex items-center gap-4 mb-4">
        <button 
          onClick={() => navigate(-1)}
          className="p-2 hover:bg-gray-100 rounded-full"
        >
          <ArrowLeft size={20} />
        </button>
        <h1 className="text-2xl font-semibold">Şirket Detay Analizi</h1>
        
        <div className="flex items-center gap-4 ml-auto">
          <select
            className="border rounded p-2"
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(Number(e.target.value))}
          >
            {months.map(month => (
              <option key={month.value} value={month.value}>
                {month.label}
              </option>
            ))}
          </select>

          <select
            className="border rounded p-2"
            value={selectedYear}
            onChange={(e) => setSelectedYear(Number(e.target.value))}
          >
            {years.map(year => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
  
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {data && Object.keys(data.current).map(company => (
          <div key={company} className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow border border-gray-200">
            <div className="p-4 border-b border-gray-200 bg-gray-50">
              <h3 className="text-lg font-medium flex items-center gap-2">
                <span>{company}</span>
              </h3>
            </div>
            <div className="p-4">
              <div className="space-y-4">
                {/* Trafik vs Diğer Dağılımı */}
                <div className="border rounded-lg p-3">
                  <h3 className="text-base font-medium mb-2">Trafik - Trafik Dışı Dağılımı</h3>
                  <div className="h-[250px]">
                    <ResponsiveContainer>
                      <PieChart>
                        <Pie
                          data={getTrafficVsOthers(company)}
                          dataKey="value"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          label={({ name, value, percent }) => 
                            `${name}: ${value} (${(percent * 100).toFixed(1)}%)`
                          }
                          innerRadius={40}
                          outerRadius={80}
                        >
                          {getTrafficVsOthers(company).map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <Tooltip formatter={(value, name, props) => [
                          `${value} Adet\n${props.payload.amount.toLocaleString('tr-TR')} TL`,
                          name
                        ]} />
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
  
                {/* Ürün Dağılımı */}
                <div className="border rounded-lg p-3">
                  <h3 className="text-base font-medium mb-2">Ürün Dağılımı</h3>
                  <div className="h-[250px]">
                    <ResponsiveContainer>
                      <PieChart>
                        <Pie
                          data={getProductDistribution(company)}
                          dataKey="value"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          label={({ name, value, percent }) => 
                            `${name}: ${value} (${(percent * 100).toFixed(1)}%)`
                          }
                          innerRadius={40}
                          outerRadius={80}
                        >
                          {getProductDistribution(company).map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <Tooltip formatter={(value, name, props) => [
                          `${value} Adet\n${props.payload.amount.toLocaleString('tr-TR')} TL`,
                          name
                        ]} />
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompanyBalanceDetail;